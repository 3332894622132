<template>
    <div class="login-container d-flex align-items-center" >
      <div v-if="errorView" class="form-login text-center">
        Try resetting your password again
        Your request to reset your password has expired or the link has already been used
      </div>
        <div class="form-login" v-if="resetView">
        <form @submit.prevent="confirm()">
            <h3 class="text-black fw-bold">Reset your Password for</h3>
            <div class="textBox-width mb-5">
                <big class="text-muted">{{email}}</big>
            </div>
            <div class="textBox-width mt-3">
                <span class="p-float-label">
                    <InputText
                        id="newpassword"
                        type="password"
                        class="p-inputtext w-100"
                        v-model="v$.newPassword.$model"
                    />
                    <label for="newpassword">New Password</label>
                </span>
                <span
                    v-if="v$.newPassword.$error && (v$.newPassword.passwordRequired && v$.newPassword.passwordRequired.$invalid)"
                    class="text-danger">{{messages.registrationValidation.passwordRequired}}
                </span>
                <span
                    v-else-if="v$.newPassword.$error &&
                        (v$.newPassword.passwordMustHaveFormat && v$.newPassword.passwordMustHaveFormat.$invalid)"
                    class="text-danger">{{messages.registrationValidation.passwordCondition}}
                </span>
            </div>
            <Button
                class="lavender-purple-background justify-content-center textBox-width mt-4"
                type="submit"
            >
                <span class="size18 fw-normal">SAVE</span>
            </Button>
        </form>
        </div>
    </div>

</template>

<script>
import useVuelidate from '@vuelidate/core';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { toasterTime, messages } from '../constants/constants';
import { AuthService } from '../../apis';
import { passwordMustHaveFormat, passwordRequired } from '../utils/validation-utils';

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'ResetPassword',
    components: {
        InputText,
        Button
    },
    data() {
        return {
            newPassword: '',
            messages,
            oobCode: '',
            email: '',
            errorView: false,
            resetView: false
        };
    },
    validations() {
        return {
            newPassword: { passwordRequired, passwordMustHaveFormat }
        };
    },
    async mounted() {
        const route = this.$route;
        this.oobCode = route.query?.oobCode;
        try {
            this.email = await AuthService.verifyPasswordResetLink(this.oobCode);
            this.resetView = true;
        } catch (err) {
            console.log(err);
            this.errorView = true;
        }
    },
    methods: {
        async confirm() {
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                return;
            }
            try {
                await AuthService.resetPassword(this.oobCode,this.newPassword);
                await AuthService.logout();
                this.$router.replace({ name: 'Login' });
            } catch (err) {
                this.$toast.add({
                    severity: 'error',
                    closable: false,
                    detail: messages.changePasswordValidation.loginAgain,
                    life: toasterTime
                });
            }
        }
    }
};
</script>
